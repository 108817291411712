import I18n from 'i18n'
import { useContext } from 'react'

import { BackendContext } from 'components/backend/BackendProvider'
import Training from 'components/common/types/Training'
import { FlashMessageContext } from 'components/flashmessages/FlashMessageProvider'
import { convertToNewTrainingDTO, convertToTrainingDTO } from '../layout/Utils'
import { capitalizeFirstLetter, smartTranslate } from 'components/common/Utils'
import axios, { AxiosError } from 'axios'
import NewTraining from 'components/common/types/NewTraining'
import NewTrainingDTO from 'components/common/types/DTOs/NewTrainingDTO'

interface TrainingHooks {
  createTraining: (training: NewTraining, createdCallback: () => void) => void
  destroyTraining: (id: number, destroyCallback: () => void) => void
  updateTraining: (updateCallbackSuccess: () => void, updateCallbackError: () => void, training: Training) => void
}

export const useTraining = (): TrainingHooks => {
  const { backend } = useContext(BackendContext)
  const { flashMessages } = useContext(FlashMessageContext)

  const createTraining = (training: NewTraining, createdCallback = () => {}): void => {
    const trainingDTO: NewTrainingDTO = convertToNewTrainingDTO(training)

    backend.schedules.create(trainingDTO).then(() => {
      flashMessages.push(I18n.t('components.trainings.creation_success'), flashMessages.duration.SHORT, flashMessages.levels.INFO)
      createdCallback()
    }).catch((e: Error | AxiosError) => {
      if (axios.isAxiosError(e)) {
        const data = e.response?.data
        if (data?.errors?.[0].status === '400') {
          const errorTitles = Object.entries<string[]>(data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k) as string}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
          flashMessages.push(I18n.t('components.trainings.errors.invalid_training', { errors: `: ${errorTitles}` }), flashMessages.duration.LONG, flashMessages.levels.ERROR)
          return
        }
      }

      flashMessages.push(I18n.t('components.trainings.errors.creation_error'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
    })
  }

  const destroyTraining = (id: number, destroyCallback: () => void): void => {
    backend.schedules.destroy(id).then(() => {
      flashMessages.push(I18n.t('components.trainings.destruction_success'), flashMessages.duration.SHORT, flashMessages.levels.INFO)
      destroyCallback()
    }).catch(() => {
      flashMessages.push(I18n.t('components.trainings.errors.destruction_error'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
    })
  }

  const updateTraining = (updateCallbackSuccess: () => void, updateCallbackError: () => void, training: Training): void => {
    const trainingDTO = convertToTrainingDTO(training)

    backend.schedules.update(trainingDTO.id, trainingDTO).then(() => {
      flashMessages.push(I18n.t('components.trainings.update_success'), flashMessages.duration.SHORT, flashMessages.levels.INFO)
      updateCallbackSuccess()
    }).catch((e: Error | AxiosError) => {
      if (axios.isAxiosError(e)) {
        const data = e.response?.data
        if (data?.errors?.[0].status === '400') {
          const errorTitles = Object.entries<string[]>(data.errors[0].detail).map(([k, v]) => `${capitalizeFirstLetter(k) as string}: ${v.map(vv => smartTranslate(vv)).join(', ')}`).join(', ')
          flashMessages.push(I18n.t('components.trainings.errors.invalid_training', { errors: `: ${errorTitles}` }), flashMessages.duration.LONG, flashMessages.levels.ERROR)
          updateCallbackError()
          return
        }
      }

      flashMessages.push(I18n.t('components.trainings.errors.update_error'), flashMessages.duration.LONG, flashMessages.levels.ERROR)
      updateCallbackError()
    })
  }

  return { createTraining, destroyTraining, updateTraining }
}
